<template>
  <div class="project-item-card col-3 pt-4">
    <div class="card card-blog h-100">
      <div class="card-header p-0 mt-n4 mx-3">
        <router-link
          class="news-card__image-container d-flex justify-content-center align-items-center
                 shadow-xl border-radius-xl bg-gradient-dark"
          :to="link"
        >
          <img
            v-if="image"
            :alt="name"
            class="news-card__image img-fluid shadow w-100 h-100 border-radius-xl"
            :src="image"
          />
          <i
            v-else
            class="d-block material-icons text-secondary fs-1"
          >
            newspaper
          </i>
        </router-link>
      </div>

      <div class="d-flex flex-column card-body p-3" v-show="projectItemId">
        <p class="mb-0 text-sm" v-if="createDate">
          {{  dateCreateString  }} //  {{ timeCreateString }} 
        </p>

        <router-link :to="link">
          <h5
            :class="[
              { 'text-primary font-weight-bolder mb-0': featured }
            ]"
          >
            {{ title }}
          </h5>
        </router-link>

        <div class="d-flex align-items-center justify-content-between mt-auto">
          <router-link
            :class="[
              'btn',
              'btn-sm',
              'mb-0',
              { 'btn-outline-primary': !featured },
              { 'btn-primary': featured },
            ]"
            :to="link"
          >
            {{
              projectItemId
                ? 'Lees bericht'
                : 'Bericht toevoegen'
            }}
          </router-link>
          
          <div class="news-card__reactions">
            <p>
              {{ reactionCount }} reacties
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseDateTimeRange } from '@/helpers/parsers';
import { getDateString, getTimeString } from '@/helpers/datetime'

export default {
  name: 'ProjectItemCard',

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    },
    projectItemId: {
      type: Number,
      default: 0,
    },
    pinnedDate: {
      type: String,
      default: '',
    },
    createDate: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true,
    },
    featured: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    link() {
      return this.projectItemId
        ? {
          name: 'edit-project-item',
          params: {
            projectItemId: this.projectItemId,
          },
        }
        : {
          name: 'new-project-item',
          params: {
            id: this.projectId,
          }
        };
    },
    dateCreateString() { return getDateString(new Date(this.createDate)) },
    timeCreateString() { return getTimeString(new Date(this.createDate)) },
    datePinnedString() { 
      return this.pinnedDate instanceof String && this.pinnedDate !== '' 
      ? getDateString(new Date(this.this.pinnedDate)) 
      : ''
    }
  },

  methods: {
    parseDateTimeRange,
  },
};
</script>

<style scoped lang="scss">
.news-card {
  &:nth-child(n + 5) {
    margin-top: var(--bs-gutter-x);
  }

  &__image {
    object-fit: cover;

    &-container {
      height: 200px;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__reactions p {
    margin-bottom: 0;
  }
}
</style>
