<template>
  <div class="row">
    <router-link
      :to="{
        name: 'edit-project',
        params: { projectId: this.$route.params?.projectId }
      }"
    >
      <span
        class="btn btn-sm btn-primary float-end d-flex"
      >
        Groep aanpassen
        <span class="material-icons-round ms-2">
          edit_note
        </span>
      </span>
    </router-link>
  </div>

  <div class="row">
    <ProjectItemCard
      description="Klik op de button om een projectitem toe te voegen"
      featured
      :isNewItem="false"
      :image="require('@/assets/img/events/meeting.webp')"
    />
    
    <ProjectItemCard
      v-for="projectItem in projectItems"
      :key="projectItem.id"
      :project-item-id="projectItem.id"
      :title="projectItem.title"
      :description="projectItem.description"
      :pinned-date="projectItem.pinnedDate"
      :create-date="projectItem.createDate"
      :image="projectItem.visualImage"
    />
  </div>
</template>

<script>
import { getAll } from '@/api/providers/projectItems';
import ProjectItemCard from '@/components/ProjectOverview/Card.vue';

export default {
  name: 'ProjectOverview',

  components: {
    ProjectItemCard,
  },

  data: () => ({
    projectItems: [],
  }),

  mounted() {
    this.loadProjectItems();
  },

  methods: {
    loadProjectItems() {
      getAll(this.$route.params.projectId)
        .then((items) => { 
          this.projectItems = items
        })
        .catch((error) => error.default());
    },
  },
};
</script>
